<template>
	<div data-route>
		<page-header
			heading="Course details"
		/>
		<div data-element="main">
			<course-edit
				:courseId="getCourseId"
				:cancelRoute="getCancelRoute"
				:isCreate="false"
				:entryRoute="'admin'"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isAdminOnly": true
		}
	}
</route>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import CourseEdit  from '@/components/courses/CourseEdit';

	export default {
		metaInfo: {
			title: 'Course details'
		},
		components: {
			PageHeader,
			CourseEdit
		},
		computed: {
			getBreadcrumbRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}`;
			},
			getCancelRoute () {
				if (!this.getCourseId) {
					return false;
				}
				return `/admin/courses/${this.getCourseId}`;
			},
			getCourseId () {
				return this.$route.params.courseId;
			}
		},
		created () {}
	};
</script>
